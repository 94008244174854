import { CookiesComponent } from './components/cookies/cookies.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { AnticipateReceivablesComponent } from './pages/anticipate-receivables/anticipate-receivables.component';

import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';

import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import player from 'lottie-web';

// Add these two
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { BuyReceivablesComponent } from './pages/buy-receivables/buy-receivables.component';
import { CareersComponent } from './pages/careers/careers.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { SliderComponent } from './components/slider/slider.component';
import { JobVacancyComponent } from './pages/careers/job-vacancy/job-vacancy.component';
import { PartnersComponent } from './components/partners/partners.component';

import { LottieComponent } from './components/lottie/lottie.component';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CareersSnackbarComponent } from './pages/home/components/careers-snackbar/careers-snackbar.component';
import { appConfig } from './app.config';

// Export this function
export function playerFactory(): any {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    LottieComponent,
    AnticipateReceivablesComponent,
    CarouselComponent,
    AboutUsComponent,
    BuyReceivablesComponent,
    CareersComponent,
    ContactsComponent,
    SliderComponent,
    JobVacancyComponent,
    PartnersComponent,
    FeedbackComponent,
    CookiesComponent,
    CareersSnackbarComponent,
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
    MatInputModule,
    MatChipsModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,

    [
      LottieModule.forRoot({ player: playerFactory }),
      LottieCacheModule.forRoot(),
    ],
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
  ],
  exports: [
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
  ],
  providers: [CookieService, ...appConfig.providers],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
