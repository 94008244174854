import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AnticipateReceivablesComponent } from './pages/anticipate-receivables/anticipate-receivables.component';
import { BuyReceivablesComponent } from './pages/buy-receivables/buy-receivables.component';
import { CareersComponent } from './pages/careers/careers.component';
import { JobVacancyComponent } from './pages/careers/job-vacancy/job-vacancy.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},

  {path: 'solucoes/antecipar-recebiveis', component: AnticipateReceivablesComponent},
  {path: 'solucoes/comprar-recebiveis', component: BuyReceivablesComponent},
  {path: 'broadfactor/sobre', component: AboutUsComponent},
  {path: 'broadfactor/trabalhe-conosco', component: CareersComponent},
  {path: 'broadfactor/trabalhe-conosco/talentos', component: JobVacancyComponent},

  {path: 'contatos', component: ContactsComponent},
  {path: '**', redirectTo: '', pathMatch: 'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
