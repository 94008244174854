import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CareersSnackbarComponent } from './components/careers-snackbar/careers-snackbar.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private snackbar: MatSnackBar) {}

  ngOnInit() {
    // this.snackbar.openFromComponent(CareersSnackbarComponent, {
    //   data: {
    //     title: 'ESTAMOS CONTRATANDO!',
    //     subtitle: 'Temos vagas em aberto. Envie seu currículo.',
    //   },
    //   horizontalPosition: 'end',
    //   verticalPosition: 'bottom',
    //   panelClass: ['career-snackbar'],
    // });
  }

  openRegister() {
    let url = 'https://register.broadfactor.com/cedente';
    let origin_campaign = localStorage.getItem('origin_campaign');
    let origin_lead = localStorage.getItem('origin_lead');
    if (origin_campaign && origin_campaign !== 'undefined') {
      url = url + '?origin_campaign=' + origin_campaign;
    }
    if (origin_campaign && origin_campaign !== 'undefined') {
      url = url + '?origin_campaign=' + origin_campaign;
    }
    if (origin_lead && origin_lead !== 'undefined') {
      if (origin_campaign && origin_campaign !== 'undefined') {
        url = url + '&origin_lead=' + origin_lead;
      } else {
        url = url + '?origin_lead=' + origin_lead;
      }
    }
    window.open(url);
  }
}
