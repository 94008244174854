import { CookieService } from 'ngx-cookie-service';
import { CookiesComponent } from './components/cookies/cookies.component';
import { MatDialog } from '@angular/material/dialog';
import {
  afterNextRender,
  Component,
  ElementRef,
  inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { RouterHelperService } from './shared/router-helper/router-helper.service';

type Metatags = {
  title: string;
  keywords: string;
  description: string;
  robot: string;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('mainContainer', { static: true })
  public mainContainer!: ElementRef;
  title = 'website';
  hasCookies: boolean = false;

  private plataformId = inject(PLATFORM_ID);
  private routerHelper = inject(RouterHelperService);

  constructor(
    private dialog: MatDialog,
    private cookieService: CookieService,
  ) {
    afterNextRender(() => {
      this.checkCookies();
      this.hasCookies ? null : this.openCookiesDialog();
    });
  }

  ngOnInit() {
  }

  checkCookies(): void {
    const value = this.cookieService.check('accepted');
    value ? (this.hasCookies = true) : (this.hasCookies = false);
  }

  openCookiesDialog(): void {
    let scrollStrategy;
    scrollStrategy = new NoopScrollStrategy();
    this.dialog.open(CookiesComponent, {
      width: '60%',
      position: { bottom: '0%' },
      disableClose: true,
      panelClass: 'class-custom-dialog',
      hasBackdrop: false,
      scrollStrategy: scrollStrategy,
    });
  }

  verifyPlatform(): void {
    if (isPlatformBrowser(this.plataformId)) {
      console.log('Browser');
    }

    if (isPlatformServer(this.plataformId)) {
      console.log('Servidor');
    }
  }
}