
import { animate, query, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent  {
  
  currentStep = 'estimateTemplate0'
  constructor(private _formBuilder: FormBuilder) {}
  firstFormGroup: FormGroup = this._formBuilder.group({firstCtrl: ['']});

  selectionChange(ev: any) {
    this.currentStep = 'estimateTemplate' + ev.selectedIndex
    
  }
}
