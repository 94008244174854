import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { keywords } from './meta/keywords';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AnticipateReceivablesComponent } from './pages/anticipate-receivables/anticipate-receivables.component';
import { BuyReceivablesComponent } from './pages/buy-receivables/buy-receivables.component';
import { CareersComponent } from './pages/careers/careers.component';
import { JobVacancyComponent } from './pages/careers/job-vacancy/job-vacancy.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { HomeComponent } from './pages/home/home.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    data: {
      seo: {
        keywords: keywords.home,
        title: 'Broadfactor - Crédito rápido para impulsionar sua empresa',
        description:
          'Somos a empresa pioneira em desenvolver e disponibilizar uma plataforma de mercado digital para o factoring colaborativo© e antecipação de recebíveis.',
          robots: 'index, follow'
      }
    },
  },

  {
    path: 'solucoes/antecipar-recebiveis',
    component: AnticipateReceivablesComponent,
    data: {
      seo: {
        keywords: keywords.antecipateReceivables,
        title: 'Antecipação de recebíveis - Venda a prazo e receba à vista',
        description:
          'Precisando de capital de giro para impulsionar sua empresa? A Broadfactor te ajuda!',
          robots: 'index, follow'
      }
    },
  },
  {
    path: 'solucoes/comprar-recebiveis',
    component: BuyReceivablesComponent,
    data: {
      seo: {
        keywords: keywords.buyReceivables,
        title: 'Compre recebíveis',
        description:
          'Tenha acesso a milhares de cedentes qualificados na plataforma com a menor taxa de inadimplência do mercado.',
          robots: 'index, follow'
      }
    },
  },
  {
    path: 'broadfactor/sobre',
    component: AboutUsComponent,
    data: {
      seo: {
        keywords: keywords.aboutUs,
        title: 'Conheça a Broadfactor',
        description:
          'A primeira plataforma digital de antecipação de recebíveis do mundo, que nasceu com o propósito de levar prosperidade aos empreendedores.',
          robots: 'index, follow'
      }
    },
  },
  {
    path: 'broadfactor/trabalhe-conosco',
    component: CareersComponent,
    data: {
      seo: {
        keywords: keywords.careers,
        title: 'Venha reescrever histórias com a Broadfactor!',
        descripton: 'Venha reescrever histórias com a Broadfactor!',
      }
    },
  },
  {
    path: 'broadfactor/trabalhe-conosco/talentos',
    component: JobVacancyComponent,
  },

  {
    path: 'contatos',
    component: ContactsComponent,
    data: {
      seo: {
        keywords: keywords.contacts,
        title: 'Entre em Contato',
        description:
          'Você pode entrar em contato através do nosso whatsapp, e-mail ou telefones, de segunda à sexta, das 8h às 17h.',
      }
    },
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
