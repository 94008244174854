import { inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SeoData } from './interfaces/seo.data';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private metaService = inject(Meta);
  private titleService = inject(Title);


  public setData(data: SeoData): void {
    this.setDescription(data.description);
    this.setKeywords(data.keywords);
    this.setRobots(data.robots);
    this.setTitle(data.title);
  }


  public setKeywords(keywords?: string): void {
    if(keywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: keywords
      })
    } else {
      this.metaService.removeTag("name='keywords'");
    }
  }

  public setDescription(description?: string): void {
    if(description) {
      this.metaService.updateTag({
        name: 'description',
        content: description,
      });
    } else {
      this.metaService.removeTag("name='description'");
    }
  }

  public setTitle(title: string = ''): void {
    this.titleService.setTitle(title);
  }

  public setRobots(robots?: string): void {
    if(robots) {
      this.metaService.updateTag({
        name: 'robots',
        content: robots,
      });
    } else {
      this.metaService.removeTag("name='robots'");
    }
  }
}

