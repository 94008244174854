import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, AfterViewInit } from '@angular/core';

import { SwiperOptions } from 'swiper';


@Component({
	selector: 'app-partners',
	templateUrl: './partners.component.html',
	styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit, AfterViewInit {


	ngOnInit() {
	}

	config: SwiperOptions = {
		slidesPerView: 5,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		spaceBetween: 30,
		breakpoints: {
			'100': {
				slidesPerView: 2,
			},
			'640': {
				slidesPerView: 2,
			},
			'768': {
				slidesPerView: 4,
			},
			'1200': {
				slidesPerView: 5
			}
		}
	}

	constructor(@Inject(PLATFORM_ID) private _platformId: Object) { }


	ngAfterViewInit(): void {
		if (isPlatformBrowser(this._platformId)) {
			this.config = {
				direction: 'horizontal',
				updateOnWindowResize: true,
				preloadImages: false
			};
		}
	}
}
