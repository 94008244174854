export const keywords = {
  home: 'Antecipação de recebíveis, antecipação na plataforma, comprar titulos, antecipar com broadfactor, bolsa digital de recebiveis, broadfactor,Antecipação de recebíveis com desconto, Como funciona a antecipação de recebíveis, Empresa de antecipação de recebíveis, Taxas de antecipação de recebíveis, Antecipação de recebíveis sem burocracia, Antecipação de recebíveis para pequenas empresas, Antecipação de recebíveis para capital de giro, Vantagens e desvantagens da antecipação de recebíveis, Antecipação de recebíveis para aumentar o fluxo de caixa, Antecipação de recebíveis versus empréstimos bancários, Melhores empresas de antecipação de recebíveis, Antecipação de recebíveis para empresas com baixo crédito,Antecipação de recebíveis para empresas em fase de crescimento.',
  antecipateReceivables:
    'crédito rápido, Antecipação de recebíveis,antecipação na plataforma, comprar titulos, antecipar com broadfactor, bolsa digital de recebiveis,broadfactor',
  buyReceivables:
    'financiador de recebíveis, crédito rápido, Antecipação de recebíveis',
  aboutUs: 'broadfactor, financiador de recebíveis',
  careers:
    'trabalhar na broadfactor, financiador de recebíveis,antecipação na plataforma, comprar titulos, antecipar com broadfactor, bolsa digital de recebiveis',
  contacts:
    'Contato broadfactor, financiador de recebíveis, crédito rápido, Antecipação de recebíveis,antecipação na plataforma, comprar titulos, antecipar com broadfactor, bolsa digital de recebiveis',
};
