import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  openRegister() {
    let url = 'https://register.broadfactor.com/cedente';
    let origin_campaign = localStorage.getItem('origin_campaign');
    let origin_lead = localStorage.getItem('origin_lead');
    if (origin_campaign && origin_campaign !== 'undefined') {
      url = url + '?origin_campaign=' + origin_campaign;
    }
    if (origin_lead && origin_lead !== 'undefined') {
      if (origin_campaign && origin_campaign !== 'undefined') {
        url = url + '&origin_lead=' + origin_lead;
      } else {
        url = url + '?origin_lead=' + origin_lead;
      }
    }

    window.open(url);
  }
}
