<div class="container">
  <section class="container__info">
    <div class="container__info--width">
      <h1 class="home__title">Precisa de ajuda?</h1>
      <p class="home__subtitle">
        Você pode entrar em contato através do nosso whatsapp, e-mail ou através
        dos telefones de contato, de segunda à sexta, das 8h às 12h, e das 13h
        às 17h. <b>Ficaremos muito felizes em poder ajudá-lo!</b>
      </p>
      <button
        style="color: #f2f2f2"
        mat-stroked-button
        onclick="window.open('https://api.whatsapp.com/send/?phone=5547992157013&text&app_absent=1')"
      >
        <img src="../../../assets/img/whats_branco.svg" alt="whats" />
        Whatsapp
      </button>
    </div>
  </section>

  <section class="container__form">
    <div class="contact">
      <div class="contact__block">
        <mat-icon>mail</mat-icon>
        <p>Podemos te ajudar pelo nosso canal de email.</p>
        <p style="color: #cc3179">contato&#64;broadfactor.com</p>
        <p style="color: #cc3179">financeiro&#64;broadfactor.com</p>
        <p style="color: #cc3179">comercial&#64;broadfactor.com</p>
        <p style="color: #cc3179">operacao&#64;broadfactor.com</p>
      </div>
      <div class="contact__block">
        <mat-icon>call</mat-icon>
        <p>Podemos te ajudar pelos telefones:</p>
        <p style="color: #cc3179">(47) 99215-7013</p>
      </div>
      <div class="contact__block">
        <img width="24px" src="../../../assets/img/whats.svg" alt="whats" />
        <p>Você pode entrar em contato pelo Whatsapp</p>
        <a
          style="color: #cc3179; font-size: 16px"
          href="https://api.whatsapp.com/send/?phone=5547992157013&text&app_absent=1"
          target="_blank"
          >Whatsapp</a
        >
      </div>
      <div class="contact__block">
        <mat-icon>place</mat-icon>
        <p><b>Localização</b></p>
        <p>
          João Pessoa, nº 2.033, NewCow Coworking, <br />
          Costa e Silva, Joinville, SC - CEP 89.218-533
        </p>
      </div>
      <div class="contact__block">
        <p><b>Nossas redes sociais</b></p>
        <a target="_blank" href="https://www.instagram.com/broadfactor1/">
          <img
            class="social_media"
            src="../../../assets/img/Vector.svg"
            alt="Instagram"
        /></a>
        <a target="_blank" href="https://www.facebook.com/broadfactor">
          <img
            class="social_media"
            src="../../../assets/img/Vector (1).svg"
            alt="Facebook"
        /></a>
        <a target="_blank" href="https://www.linkedin.com/company/broadfactor/">
          <img
            class="social_media"
            src="../../../assets/img/Vector (2).svg"
            alt="Linkedin"
        /></a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UChxZcuH2MjPLA7ebO4VKqUA"
        >
          <img
            class="social_media"
            src="../../../assets/img/Vector (3).svg"
            alt="Youtube"
        /></a>
      </div>
    </div>
    <div class="form">
      <img
        *ngIf="!clickedButton"
        src="../../../assets/img/Atendimento exclusivo.svg"
        alt="Atendimento exclusivo"
      />
      <img
        *ngIf="clickedButton"
        [src]="
          sendedMail
            ? '../../../assets/img/email-enviado.svg'
            : '../../../assets/img/email-erro.svg'
        "
        alt="Atendimento exclusivo"
      />
      <form #formChild="ngForm" [formGroup]="form">
        <h2>Fale com a gente</h2>
        <app-feedback
          *ngIf="clickedButton"
          [sendedMail]="sendedMail"
        ></app-feedback>
        <mat-form-field appearance="outline">
          <mat-label>Nome completo </mat-label>
          <input type="text" matInput required formControlName="name" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>E-mail</mat-label>
          <input type="email" matInput required formControlName="email" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Assunto</mat-label>
          <input type="text" matInput required formControlName="subject" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Mensagem</mat-label>
          <textarea
            rows="10"
            matInput
            required
            formControlName="msg"
          ></textarea>
        </mat-form-field>

        <button
          mat-flat-button
          [disabled]="!form.valid"
          color="primary"
          (click)="send()"
        >
          Enviar
        </button>
      </form>
    </div>
  </section>
</div>
