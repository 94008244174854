import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-careers-snackbar',
  templateUrl: './careers-snackbar.component.html',
  styleUrls: ['./careers-snackbar.component.scss'],
})
export class CareersSnackbarComponent {
  constructor(
    private router: Router,
    public snackBarRef: MatSnackBarRef<CareersSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  redirect() {
    this.snackBarRef.dismiss();
    this.router.navigate(['broadfactor', 'trabalhe-conosco']);
  }
}
