import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-anticipate-receivables',
  templateUrl: './anticipate-receivables.component.html',
  styleUrls: ['./anticipate-receivables.component.scss'],
})
export class AnticipateReceivablesComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['utm_campaign']) {
        localStorage.setItem('origin_campaign', params['utm_campaign']);
      }
      if (params['utm_source']) {
        localStorage.setItem('origin_lead', params['utm_source']);
      }
    });
  }

  openRegister() {
    let url = 'https://register.broadfactor.com/cedente';
    let origin_campaign = localStorage.getItem('origin_campaign');
    let origin_lead = localStorage.getItem('origin_lead');
    if (origin_campaign && origin_campaign !== 'undefined') {
      url = url + '?origin_campaign=' + origin_campaign;
    }
    if (origin_lead && origin_lead !== 'undefined') {
      if (origin_campaign && origin_campaign !== 'undefined') {
        url = url + '&origin_lead=' + origin_lead;
      } else {
        url = url + '?origin_lead=' + origin_lead;
      }
    }
    window.open(url);
  }

  slides = [
    {
      url: 'https://source.unsplash.com/1600x900/?nature,water',
    },
    {
      url: 'https://source.unsplash.com/1600x1600/?nature,forest',
    },
  ];
}
