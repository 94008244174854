import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, ViewChild, ElementRef, ViewEncapsulation,afterNextRender } from '@angular/core';
import { SwiperOptions } from 'swiper/types';


@Component({
	selector: 'app-partners',
	templateUrl: './partners.component.html',
	styleUrls: ['./partners.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PartnersComponent {
  @ViewChild('partnersSwiper') swiper!: ElementRef<any>;

	swiperConfig: SwiperOptions = {
		spaceBetween: 30,
		slidesPerView: 5,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		direction: 'horizontal',
		breakpoints: {
			'100': {
				slidesPerView: 2,
			},
			'640': {
				slidesPerView: 2,
			},
			'768': {
				slidesPerView: 4,
			},
			'1200': {
				slidesPerView: 5
			}
		},
		autoplay: {
			delay: 5000,
		},
		updateOnWindowResize: true,
	}

	partners: {
		src: string;
		name: string;
	}[] = [
		{
			src: "../../../assets/img/partners/aws.webp",
			name: "aws"
		},
		{
			src: "../../../assets/img/partners/Bry.svg",
			name: "bry"
		},
		{
			src: "../../../assets/img/partners/cropped-Logo-Site-Somma.ai--768x219.png",
			name: "somma"
		},
		{
			src: "../../../assets/img/partners/Omie.webp",
			name: "omie",
		},
		{
			src: "../../../assets/img/partners/RD.webp",
			name: "rd",
		},
		{
			src: "../../../assets/img/partners/Serasa.webp",
			name: "serasa",
		},
		{
			src: "../../../assets/img/partners/SERPRO.webp",
			name: "serpro",
		},
		{
			src: "../../../assets/img/partners/silva.webp",
			name: "silva",
		},
		{
			src: "../../../assets/img/partners/SRM.webp",
			name: "srm",
		}
	]


	constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
		afterNextRender(() => {
			this.handleUpdateSwiperConfig();
		})
	}

	handleUpdateSwiperConfig(): void {
		if (isPlatformBrowser(this._platformId)) {
			this.swiperConfig = {
				...this.swiperConfig,
				direction: 'horizontal',
			};
	};
	Object.assign(this.swiper.nativeElement, this.swiperConfig);
	this.swiper.nativeElement.initialize();
	}
}
