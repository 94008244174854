import { JobVacancyBodyInterface } from './job-vacancy-body.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobVacancyService {
  constructor(private httpClient: HttpClient) {}

  sendJobVacancy(body: JobVacancyBodyInterface): Observable<string> {
    return this.httpClient.post<string>(`${environment.api}/candidacy`, body);
  }
}
