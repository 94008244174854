<footer>
	<div class="footer-box">
		<span class="footer-box__title">A Broadfactor</span>
		<a class="footer-box__link" routerLink="broadfactor/sobre" >Quem somos</a>
		<a class="footer-box__link" routerLink="broadfactor/trabalhe-conosco" >Trabalhe conosco</a>

	</div>
	<div class="footer-box">
		<span class="footer-box__title">Soluções</span>
		<a class="footer-box__link" routerLink="solucoes/antecipar-recebiveis" >Antecipar recebíveis</a>
		<a class="footer-box__link" routerLink="solucoes/comprar-recebiveis" >Comprar recebiveis</a>
		<a class="footer-box__link" href="https://antecipagov.com.br/" >Antecipagov</a>

	</div>
	<div class="footer-box">
		<span class="footer-box__title">Dúvidas</span>
		<a class="footer-box__link" target="_blank" href="https://blogbroadfactor.com" >Blog</a>
		<a class="footer-box__link" target="_blank" href="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/Termos/cedente/Termos_+de_Uso_Broadfactor.pdf" >Termos de uso</a>
		<a class="footer-box__link" target="_blank" href="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/Termos/cedente/Pol%C3%ADtica+de+Privacidade+Broadfactor.pdf" >Política de Privacidade</a>
	</div>
	<div class="footer-box">
		<div class="footer-box">
			<span class="footer-box__title">Contato</span>
			<span class="footer-box__content">contato&#64;broadfactor.com</span>
			<span class="footer-box__content">(47) 99215-7013</span>
		</div>
		<div class="footer-box">
			<span class="footer-box__title">Endereço</span>
			<span class="footer-box__content">João Pessoa, nº 2.033, <br> NewCow Coworking, <br>Costa e Silva, Joinville <br> CEP 89.218-533</span>
		</div>
	</div>
	<div class="footer-box">
		<div class="footer-box__media">
			<a target="_blank" href="https://www.instagram.com/broadfactor1/">
				<img class="social_media" src="../../../assets/img/footer/Vector-1.png" alt="Instagram"></a>
			  <a target="_blank" href="https://www.facebook.com/broadfactor">
				<img class="social_media" src="../../../assets/img/footer/Vector.png" alt="Facebook"></a>
			  <a target="_blank" href="https://www.linkedin.com/company/broadfactor/">
				<img class="social_media" src="../../../assets/img/footer/Vector-2.png" alt="Linkedin"></a>
			  <a target="_blank" href="https://www.youtube.com/channel/UChxZcuH2MjPLA7ebO4VKqUA">
				<img class="social_media" src="../../../assets/img/footer/Vector-3.png" alt="Youtube"></a>

		</div>
		<img class="selo" width="100" height="100" src="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/Termos/seloNivelIinv.png"
		alt="selo Nivel Iinv" data-src="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/Termos/seloNivelIinv.png">
	</div>




</footer>
