<section>
  <div>
    <h4>
      {{ data.title }}
    </h4>
    <h3>
      {{ data.subtitle }}
    </h3>
  </div>
  <div matRipple>
    <span (click)="redirect()">Saiba mais</span>
  </div>
  <div matRipple>
    <mat-icon (click)="snackBarRef.dismiss()">close</mat-icon>
  </div>
</section>
