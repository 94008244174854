<div class="container">
  <section class="container__info">
    <div class="container__info--width">
      <div class="container-info--text">
        <h2 class="home__title">
          Seja um financiador de recebíveis parceiro da Broadfactor
        </h2>
        <p class="home__subtitle">
          Compre títulos com segurança na plataforma que já realizou mais de
          R$350 milhões em antecipações.
        </p>
        <button
          mat-flat-button
          color="primary"
          onclick="window.open('https://register.broadfactor.com/comprador')"
        >
          COMEÇAR AGORA!
        </button>
      </div>
    </div>
  </section>

  <!-- 	<section class="container-benefits">
		<div class="container-benefits__width">
			<div class="container-benefits__grid">
				<div class="container-benefits__box">
					<h2>Por que comprar títulos com a Broadfactor?</h2>
				</div>
				<div class="container-benefits__box">
					<img alt="Expanda sua base" src="./assets/img/Expanda sua base.svg">
					<h3>Expanda sua base</h3>
					<span>Acesso a milhares de cedentes qualificados.</span>
				</div>
				<div class="container-benefits__box">
					<img alt="Baixa inadimplência" src="https://s3.sa-east-1.amazonaws.com/antecipagov.public.docs/images/Baixa+inadimplencia.svg">
					<h3>Baixa inadimplência</h3>
					<span>O menor índice do mercado com apenas 0,38%.</span>
				</div>
				<div class="container-benefits__box">
					<img alt="Sem papel" class="sem-papel" src="./assets/img/Sem papel.svg">
					<h3>Sem papel</h3>
					<span>Assine documentos online e reveja-os à mão em todos os seus dispositivos.</span>
				</div>
				<div class="container-benefits__box">
					<img alt="Atendimento exclusivo" src="./assets/img/Atendimento exclusivo.svg">
					<h3>Atendimento exclusivo</h3>
					<span>Suporte rápido e humanizado com apoio da tecnologia, sem bots ou robôs.</span>
				</div>
				<div class="container-benefits__box">
					<img alt="Totalmente online e digital" src="./assets/img/Totalmente online e digital.svg">
					<h3>Totalmente online e digital</h3>
					<span>Cadastro, análise, assinatura de documentos e negociação, tudo de forma online e segura.</span>
				</div>
			</div>
		</div>
	</section> -->

  <section class="container__home">
    <div>
      <h2 class="home__title">Posso ser um financiador parceiro?</h2>
      <br />
      <p>
        <b
          >Sim! Basta ser Factoring, securitizadora, ESC, FIDC ou Instituição
          Financeira.</b
        >
      </p>
      <br />
      <p>
        Ser um parceiro Broadfactor é ter a oportunidade de negociar mais, de
        forma fácil e eficiente. Por meio de nossa plataforma digital de
        antecipação de recebíveis, conectamos você a milhares de cedentes
        qualificados em todo o país.
      </p>

      <p>
        Além de contar com nosso atendimento totalmente humanizado, você
        ampliará sua base de cedentes e realizará mais negócios!
      </p>
    </div>
    <img
      src="./assets/img/parceiro.png"
      width="500px"
      alt="Mãos dadas fechando uma parceria
      "
      style="margin-left: 80px"
    />
  </section>

  <button
    mat-flat-button
    color="primary"
    style="margin: 0 auto; display: block"
    onclick="window.open('https://register.broadfactor.com/comprador')"
  >
    QUERO SER UM PARCEIRO
  </button>

  <section style="background-color: #f2f2f2">
    <div class="container__home">
      <img
        src="./assets/img/Venha expandir com a broadfactor.png"
        style="margin-bottom: -20px; margin-right: 20%"
        width="400px"
        alt="Mão segurando um celular com um gráfico crescendo e ultrapassando a tela do celular
        "
      />
      <div>
        <h2 class="home__title">Expanda com a Broadfactor</h2>
        <p class="home__subtitle">
          Tenha acesso a milhares de cedentes qualificados na plataforma com a
          menor taxa de inadimplência do mercado
        </p>
        <button
          mat-flat-button
          color="primary"
          onclick="window.open('https://register.broadfactor.com/comprador')"
        >
          Quero ser um Parceiro
        </button>
      </div>
    </div>
  </section>

  <app-carousel></app-carousel>

  <section
    class="container__info"
    style="background-color: white; color: black"
  >
    <div class="container__info--width">
      <div class="container-info--text">
        <h2 class="home__title">
          <b>São mais de 100 mil títulos já antecipados!</b>
        </h2>
        <p class="home__subtitle">
          Um mundo de cedentes qualificados esperam por você, vai querer ficar
          de fora dessa?
        </p>
        <button
          mat-flat-button
          color="primary"
          onclick="window.open('https://register.broadfactor.com/comprador')"
        >
          Cadastre-se agora
        </button>
      </div>
    </div>
  </section>

  <section class="container__plans">
    <div class="options">
      <h2><b>Plano para financiadores</b></h2>
      <mat-list>
        <mat-list-item>
          <mat-icon mat-list-icon>done</mat-icon>
          <div mat-line>Compre títulos com total segurança.</div>
        </mat-list-item>
        <mat-list-item>
          <mat-icon mat-list-icon>done</mat-icon>
          <div mat-line>O menor índice de inadimplência do mercado.</div>
        </mat-list-item>
        <mat-list-item>
          <mat-icon mat-list-icon>done</mat-icon>
          <div mat-line>Acesso a milhares de cedentes qualificados.</div>
        </mat-list-item>
      </mat-list>

      <h1>R$ 300<small>/mês</small></h1>
      <button
        mat-flat-button
        color="primary"
        onclick="window.open('https://register.broadfactor.com/comprador')"
      >
        ASSINAR
      </button>
    </div>
    <img
      src="./assets/img/plano-financiador.png"
      alt="Notebook aberto com dinheiro em cima e um foguete decolando ao fundo "
    />
  </section>

  <p style="text-align: center; margin: 20px auto 80px; padding: 0 40px">
    <b>Sem fidelidade! </b> Nossos clientes estão com a gente porque gostam do
    nosso trabalho.​
  </p>
</div>
