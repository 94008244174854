<div class="container">
  <h1>Banco de Talentos</h1>
  <div class="action">
    <a
      mat-stroked-button
      color="primary"
      target="_blank"
      href="https://www.linkedin.com/company/broadfactor/jobs/"
      >Candidate-se pelo LinkedIn</a
    >
  </div>

  <h2>Sobre a Broadfactor e a cultura</h2>

  <p>
    Nossos Broaders seguem seu dia a dia de forma leve, organizada e séria,
    mantendo um equilíbrio entre a boa vivência e partilhando ideias que possam
    mudar a história de nossos usuários.
  </p>
  <p>
    Acreditamos em um futuro próspero para todas as empresas que antecipam
    conosco. Sabemos que somente juntos poderemos crescer e sermos cada vez mais
    fortes.
  </p>
  <!-- <p>Somos uma empresa totalmente adaptada ao conceito de Home-Office. Nosso time trabalha de casa ou de qualquer
		lugar
		onde você possa estar com seu notebook, um celular e uma conexão à internet.
	</p> -->

  <h2>Conheça os times da Broadfactor</h2>
  <div class="container__blocks">
    <div class="item">
      <mat-icon color="primary">campaign</mat-icon>
      <p><b>Marketing</b></p>
      <p>
        Responsável por toda a identidade visual da Broadfactor, bem como toda a
        parte estratégica e funcional do marketing digital.
      </p>
    </div>
    <div class="item">
      <mat-icon color="primary">chat_bubble</mat-icon>
      <p><b>Customer Success</b></p>
      <p>
        Responsável pelo atendimento, vendas de títulos, acompanhamento e
        suporte nas operações do início ao fim do processo.
      </p>
    </div>
    <div class="item">
      <mat-icon color="primary">memory</mat-icon>
      <p><b>Tecnologia</b></p>
      <p>
        Responsável por desenvolver e manter nossas plataformas atualizadas,
        funcionais e intuitivas, para que os times e clientes possam
        utilizá-los.
      </p>
    </div>
    <div class="item">
      <mat-icon color="primary">people_alt</mat-icon>
      <p><b>Desenvolvimento Humano e Organizacional</b></p>
      <p>
        Responsável pelo recrutamento de novos Broaders, cultura, engajamento e
        aprimoramento de colaboradores, juntamente com os líderes.
      </p>
    </div>
    <div class="item">
      <mat-icon color="primary">work</mat-icon>
      <p><b>Comercial</b></p>
      <p>
        Responsável por apresentar a Broadfactor aos futuros cedentes, via
        inbound e outbound. Cuida da qualificação inicial, aprovação de crédito
        e fidelização.
      </p>
    </div>
  </div>

  <h2>Diferenciais</h2>
  <ul>
    <li>Conhecimento no mercado de recebíveis</li>
    <li>Experiência no segmento financeiro</li>
    <li>Experiência em Startup</li>
  </ul>

  <h2>LGPD</h2>

  <p>
    A Broadfactor adota uma política para garantir controle e transparência no
    tratamento de dados de todas as pessoas, inclusive aquelas interessadas em
    fazer parte do nosso time. As informações de candidatos à vagas abertas
    durante o processo seletivo e de candidatos cadastrados no banco de talentos
    são tratadas rigorosamente de acordo com a LGPD.
  </p>
  <p>
    O compartilhamento desses dados ocorrerá apenas com colaboradores da
    Broadfactor que estejam diretamente envolvidos no processo, terceiros e
    parceiros de negócios que sejam relevantes para o processo de recrutamento e
    seleção.
  </p>
  <p>
    A Broadfactor irá manter o armazenamento dos dados do candidato por
    aproximadamente um (1) ano, ou o tempo necessário para realização do
    processo de seleção. E sempre que desejar, o candidato pode solicitar a
    exclusão dos seus dados do nosso banco de talentos. Caso ainda tenha dúvidas
    relacionadas à LGPD, você pode entrar em contato com alguém da nossa equipe
    através do e-mail contato&#64;broadfactor.com.
  </p>

  <h2 class="text-center">Candidate-se</h2>
  <form #form="ngForm" class="form" [formGroup]="formCliente">
    <app-feedback
      *ngIf="clickedButton"
      [sendedMail]="sendedMail"
    ></app-feedback>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Nome</mat-label>
      <input matInput required formControlName="name" />
    </mat-form-field>

    <table class="full-width" cellspacing="0">
      <tr>
        <td style="padding-right: 8px">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Telefone</mat-label>
            <input matInput type="number" required formControlName="phone" />
          </mat-form-field>
        </td>

        <td style="padding-right: 8px">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>LinkedIn</mat-label>
            <input matInput required formControlName="linkedIn" />
          </mat-form-field>
        </td>

        <td>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Selecione uma área</mat-label>
            <mat-select required formControlName="candidacy">
              <mat-option value="marketing">Marketing</mat-option>
              <mat-option value="marketing">Costumer Success</mat-option>
              <mat-option value="marketing">Tecnologia</mat-option>
              <mat-option value="marketing"
                >Desenvolvimento humano e organizacional</mat-option
              >
              <mat-option value="marketing">Comercial</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </table>

    <p>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput required formControlName="email" />
      </mat-form-field>
    </p>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Mensagem</mat-label>
      <textarea matInput required formControlName="msg"></textarea>
    </mat-form-field>

    <mat-checkbox
      formControlName="acceptedTerms"
      (click)="checkbox()"
      color="primary"
    >
      <p class="terms">
        Eu li e aceito os termos de privacidade da Broadfactor.
      </p>
    </mat-checkbox>
    <mat-chip-listbox aria-label="Color selection">
      <mat-chip-option *ngIf="file"
        >{{ file.name }}
        <mat-icon (click)="removeDocument()" class="close-icon">close</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
    <div style="margin-top: 20px; display: flex; align-items: flex-start">
      <div style="margin-right: 10px; display: flex; flex-direction: column">
        <input
          #attachFile
          hidden="true"
          formControlName="file"
          type="file"
          (change)="csvInputChange($event)"
        />
        <button mat-stroked-button color="primary" (click)="attachFile.click()">
          <mat-icon>attach_file</mat-icon> Anexar Currículo
        </button>
      </div>

      <button
        mat-flat-button
        color="primary"
        [disabled]="!formCliente.valid"
        (click)="send()"
      >
        Enviar
      </button>
    </div>
  </form>
</div>
