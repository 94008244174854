<div class="container">

	<h3 class="subtitle">COMO FUNCIONA</h3>
	<h1 class="title">4 etapas para a antecipação de recebíveis</h1>

	<div class="container__stepper">
		<mat-stepper [linear]="false" orientation="vertical" (selectionChange)="selectionChange($event)">
			<mat-step [stepControl]="firstFormGroup">
				<ng-template matStepLabel>Cadastro</ng-template>
				<ng-template #estimateTemplate0>
					<div class="stepper__item">
						<img src="../../../assets/img/cadastro.png" alt="Cadastro">
						<div>
							<small>1 de 4</small>
							<h1>Cadastro</h1>
							<p>Cadastre os dados da sua empresa e aguarde que nossa equipe de proteção aprove o crédito através da verificação de segurança, que conta com mais de nove etapas para minimizar tentativas de fraude.</p>
						</div>
					</div>

				</ng-template>
			</mat-step>
			<mat-step [stepControl]="firstFormGroup">
				<ng-template matStepLabel>Envio das notas fiscais</ng-template>
				<ng-template #estimateTemplate1>
					<div class="stepper__item">
						<img src="../../../assets/img/envio-das-nfs.png" alt="Envio das notas fiscais">
						<div>
							<small>2 de 4</small>
							<h1>Envio das notas fiscais</h1>
							<p>Importe suas notas fiscais de vendas para nossa plataforma, a fim de disponibilizá-las para centenas de factorings e FIDCs parceiras.</p>
							<p>Com o uso de tecnologia encontramos o comprador ideal para seus recebíveis.</p>
						</div>
					</div>
				</ng-template>
			</mat-step>
			<mat-step [stepControl]="firstFormGroup">
				<ng-template matStepLabel>Assinatura dos documentos</ng-template>
				<ng-template #estimateTemplate2>
					<div class="stepper__item">
						<img src="../../../assets/img/assinatura-de-documentos.png" alt="Assinatura dos documentos">
						<div>
							<small>3 de 4</small>
							<h1>Assinatura digital dos documentos</h1>
							<p>Assine digitalmente os documentos da operação com certificados digitais, que têm plena validade jurídica.</p>
							<p>Eles podem ser assinados usando qualquer dispositivo. Não se preocupe, o sistema usa um par de chaves criptográficas que nunca se repetem. <b>Seus dados estão sempre protegidos!</b> </p>
						</div>
					</div>
				</ng-template>
			</mat-step>
			<mat-step [stepControl]="firstFormGroup">
				<ng-template matStepLabel>Dinheiro na sua conta</ng-template>
				<ng-template #estimateTemplate3>
					<div class="stepper__item">
						<img src="../../../assets/img/dinheiro-na-conta.png" alt="Dinheiro na sua conta">
						<div>
							<small>4 de 4</small>
							<h1>Dinheiro na sua conta</h1>
							<p>Pronto! Agora é só aguardar a factoring depositar diretamente na conta da sua empresa.</p>
							<p>Viu como aqui na Broadfactor, o processo é simples, prático e seguro? Usando nossa plataforma 100% digital, é fácil obter o crédito que você precisa.</p>
						</div>
					</div>
				</ng-template>
			</mat-step>

			<form [formGroup]="firstFormGroup" style="display: none;">
				<ng-template matStepLabel>Fill out your name</ng-template>
				<mat-form-field>
					<input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
				</mat-form-field>
				<div>
					<button mat-button matStepperNext>Next</button>
				</div>
			</form>
		</mat-stepper>

		<ng-container [ngTemplateOutlet]="
		  currentStep === 'estimateTemplate0' ? estimateTemplate0 :
		  currentStep === 'estimateTemplate1' ? estimateTemplate1 :
		  currentStep === 'estimateTemplate2' ? estimateTemplate2 : estimateTemplate3"></ng-container>

	</div>

</div>

