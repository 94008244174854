  <section class="swiper">
    <swiper-container init="false" #partnersSwiper>
      <swiper-slide class="swiper-slide">
        <h2>Parceiros</h2>
      </swiper-slide>
      @for (partner of partners; track partner.name) {
        <swiper-slide class="swiper-slide" lazy="true">
            <img  [src]="partner.src" [alt]="partner.name + 'partner'" loading="lazy">
        </swiper-slide>
      }
    </swiper-container>
    <div class="swiper-pagination bullet-partners"></div>
  </section>

