import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
  afterNextRender
} from '@angular/core';
import { SwiperOptions } from 'swiper/types';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent {
  @ViewChild('carouselSwiper') swiper!: ElementRef<any>;

  swiperConfig: SwiperOptions = {
    centeredSlides: true,
    roundLengths: true,
    navigation: true,
    loop: true,
    loopPreventsSliding: true,
    updateOnWindowResize: true,
    breakpoints: {
      240: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      480: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
    },
		autoplay: {
			delay: 5000,
		},
		injectStyles: [
			`
				.swiper-button-prev {
					  left: 22px;
  					color: #CC3179;
				}

				.swiper-button-next {
						right: 22px;
  					color: #CC3179;
				}

        @media (max-width: 1024px) {
				  .swiper-button-next {
              right: 8px;
          }

          .swiper-button-prev {
            left: 8px;
          }
        }
			`
		]
  };

  clients: {name: string, role: string, description: string}[] = [
    {
      name: 'Eduardo',
      role: 'Cedente Broadfactor',
      description:
        'Minha empresa sempre foi bem pequena. Porém, estamos crescendo 2000% este ano. O atendimento de vocês está sendo excepcional, nem sei como agradecer. Por mais que neste momento eu use o dinheiro nas antecipações, sei que logo recupero na entrega dos produtos.',
    },
    {
      name: 'Marcones',
      role: 'Cedente Broadfactor',
      description:
        'Cadastrei minha empresa aí com vocês faz anos. Se não fosse vocês minha empresa nem existia mais. E hoje, graças a Deus, esse tempo todo que a gente trabalha juntos a gente está crescendo e foi tudo pela ajuda de vocês. Com certeza a gente vai bem mais longe”',
    },
    {
      name: 'Eduardo',
      role: 'Financiador parceiro Broadfactor',
      description:
        'O trabalho de vocês agrega valor para muitos empreendedores e indiretamente gera muitos empregos, contribuindo para a economia e continuidade da evolução do nosso país.  Neste ano pudemos ver com bons olhos a experiência que adquirimos e é com muita satisfação que até setembro alcancei um retorno líquido de 25,63% sobre nossa carteira.',
    },
    {
      name: 'Marcelo',
      role: 'Cedente Broadfactor',
      description:
        'Nem sei como agradecer a vocês hoje. Salvaram minha semana! Pensa em uma pessoa apreensiva para pagar os funcionários. Muito obrigada pela parceria!',
    },
  ];

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
    afterNextRender(() => {
      this.handleUpdateSwiperConfig();
    })
  }

	handleUpdateSwiperConfig(): void {
		if(isPlatformBrowser(this._platformId)) {
				this.swiperConfig = {
					...this.swiperConfig,
					direction: 'horizontal',
				};
		};
		Object.assign(this.swiper.nativeElement, this.swiperConfig);
		this.swiper.nativeElement.initialize();
	}
}
