<div class="container">
  <p class="message">
    Utilizamos cookies para melhorar a sua experiência no nosso site. Por favor,
    acesse nossa
    <a
      href="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/Termos/cedente/Pol%C3%ADtica+de+Privacidade+Broadfactor.pdf"
      >Política de Privacidade</a
    >
    para mais informações.
  </p>
  <div class="buttons-container">
    <button (click)="reject()" class="reject">Recusar</button>
    <button (click)="accept()" class="accept">Aceitar</button>
  </div>
</div>
