<section class="container-carousel">
  <h1 class="title">Conheça a opinião dos nossos clientes</h1>
    <swiper-container class="swiper-container swiper-wrapper"
    init="false"
    #carouselSwiper>
      @for(client of clients; track client.name) {
        <swiper-slide class="swiper-slide slide">
          <div class="content">
            <mat-icon>account_circle</mat-icon>
            <p>{{client.description}}</p>
            <small>{{client.name}}</small>
            <small>{{client.role}}</small>
          </div>
        </swiper-slide>
       }
  </swiper-container>
</section>