<section class="container__title">
  <h2>Desde 2011 prosperando empresas e <span>realizando sonhos!</span></h2>
  <p>
    Queremos entender a sua necessidade e oferecer o crédito através de uma
    plataforma cheia de tecnologia e inovações.
  </p>
</section>

<section class="container__home">
  <img
    src="./assets/img/quem-somos.png"
    width="400px"
    style="margin-right: 50px"
    alt="Duas mulheres e um homem trabalhando de diferentes lugares"
  />

  <div>
    <h2 class="home__title">Quem Somos?</h2>
    <br />
    <p>
      <b>Somos a Broadfactor. </b> A primeira plataforma digital de antecipação
      de recebíveis do mundo, que nasceu com o propósito de levar prosperidade
      aos empreendedores.
    </p>
    <br />
    <p>
      Acreditamos no modelo H2H (humano para humano), com a convicção de que não
      apenas oferecemos crédito para empresas, mas também
      <b>ajudamos a transformar sonhos em negócios de sucesso.</b>
    </p>
  </div>
</section>

<section class="company-requirements">
  <div class="company-requirements__block">
    <div class="company-requirements__item">
      <h2 style="color: #cc3179">+100 mil</h2>
      <p>Recebíveis antecipados</p>
    </div>
    <div class="company-requirements__item">
      <h2 style="color: #cc3179">R$ 500 milhões</h2>
      <p>Em antecipações realizadas</p>
    </div>
    <div class="company-requirements__item">
      <h2 style="color: #cc3179">+1,6 mil</h2>
      <p>Empresas prosperando</p>
    </div>
  </div>
</section>

<section class="container__home" style="margin: 0 auto">
  <app-lottie json="assets/lottie/Coração_1.json"></app-lottie>
  <div>
    <h2 style="font-weight: 800">
      Queremos fazer parte da sua história.<br />
      <span style="color: #cc3179">Juntos, prosperamos! </span>
    </h2>
    <p style="max-width: 600px; margin: 0 auto 0 auto">
      A Broadfactor nasceu com o propósito de contribuir com o sonho do
      empreendedor. Muito mais que só crédito. O colaborativo sempre foi o nosso
      forte.
    </p>
  </div>
</section>

<section class="our-propouse">
  <p>Nosso propósito</p>
  <h2>Buscamos trazer prosperidade aos empreendedores</h2>
  <h4>
    Quando você escolhe a Broadfactor, você está escolhendo o melhor para o
    futuro do seu negócio.
  </h4>
</section>

<!-- <section class="container-benefits">
  <div class="container-benefits__width">
    <div class="container-benefits__grid">
      <div class="container-benefits__box">
        <h2>Nossos Valores!</h2>
      </div>
      <div class="container-benefits__box">
        <img alt="Paixão" src="./assets/img/Paixao.svg">
        <h3>Paixão</h3>
        <span>Somos apaixonados pelo que fazemos. Só quem é apaixonado de verdade entrega o melhor de si mesmo.</span>
      </div>
      <div class="container-benefits__box">
        <img alt="Honestidade" src="./assets/img/Honestidade.svg">
        <h3>Honestidade</h3>
        <span>Presente no DNA da Broadfactor, esse é o principal valor em nosso mercado. Transparência acima de tudo.
        </span>
      </div>
      <div class="container-benefits__box">
        <img alt="Confiança" src="./assets/img/Confianca.svg">
        <h3>Confiança</h3>
        <span>Cumprimos o que lhe é prometido. Nosso serviço é de alta qualidade, com garantia e respeito de prazos.</span>
      </div>
      <div class="container-benefits__box">
        <img alt="Conexão" src="./assets/img/Conexao.svg">
        <h3>Conexão</h3>
        <span>Desde 2011 conectando compradores e vendedores de recebíveis para o negócio perfeito.</span>
      </div>
      <div class="container-benefits__box">
        <img alt="Excelência" src="./assets/img/Excelencia.svg">
        <h3>Excelência</h3>
        <span>Milhares de empreendedores confiam em nosso negócio para realizar seus sonhos, por isso não toleramos erros.</span>
      </div>
    </div>
  </div>
</section> -->

<section class="careers">
  <div>
    <h1>Seja um Broader!</h1>
    <p>E ajude a mudar a história de milhares de empreendedores.</p>

    <button mat-raised-button [routerLink]="['/broadfactor/trabalhe-conosco']">
      Confira nossas vagas
    </button>
  </div>
  <img src="./assets/img/Seja um Broader.png" alt="Seja um Broader" />
</section>
