import { inject, Injectable } from '@angular/core';
import { SeoService } from '../seo/seo.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, Observable, switchMap } from 'rxjs';
import { RouteData, SeoData } from '../seo/interfaces/seo.data';

@Injectable({
  providedIn: 'root'
})
export class RouterHelperService {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private seoService = inject(SeoService);

  constructor() {
    this.setupSeoMetatags();
  }

  private setupSeoMetatags(): void {
    this.router.events
    .pipe(
      filter(
        (event => {
          return event instanceof NavigationEnd})
      ),
      switchMap(
        () => {
          return this.getChild(this.route).data as Observable<RouteData> })
    )
      .subscribe(({ seo }) => {
        this.seoService.setData(seo);
      });
  }

  private getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
