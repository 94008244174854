import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
  constructor(
    private cookieService: CookieService,
    private dialog: DialogRef
  ) {}

  ngOnInit() {}

  accept(): void {
    this.cookieService.set('accepted', 'true');
    this.dialog.close();
  }
  reject(): void {
    this.cookieService.deleteAll();
    this.cookieService.set('accepted', 'false');
    this.dialog.close();
  }
}
